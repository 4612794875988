import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/http-service/http-service.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { UserListDB, USERLISTDB } from 'src/app/shared/tables/list-users';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class ListUserComponent implements OnInit {
  public user_list = []

  public tableItem$: Observable<UserListDB[]>;
  public searchText;
  total$: Observable<number>;

  constructor(public service: TableService,
    private httpService:HttpService
  ) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(USERLISTDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  getAllUsers(){
    this.httpService.getData('admin/users')
    .subscribe((res: any) => {
      console.log('category',res);
      if (res.ok) {
       this.tableItem$ = res.users
      }
    }, (err: any) => {
     
    })
  }

  ngOnInit() {
    this.getAllUsers()
  }

}

