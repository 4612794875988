<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>User Details</h5>
        </div>
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/create-user'">Create User</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" >Actions</th>
                                <th scope="col" sortable="avatar" (sort)="onSort($event)">Avtar</th>
                                <th scope="col" sortable="fName" (sort)="onSort($event)">First Name</th>
                                <th scope="col" sortable="lName" (sort)="onSort($event)">Last Name</th>
                                <th scope="col" sortable="email" (sort)="onSort($event)">Mobile</th>
                                <th scope="col" sortable="last_login" (sort)="onSort($event)">Status</th>
                                <th scope="col" sortable="role" (sort)="onSort($event)">Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tableItem$">
                                <td>
                                    <a href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                                </td>
                                <td scope="row">
                                    <img [src]="item.userImage" class="imgTable" style="width: 20px">
                                </td>
                                <td>
                                    {{item.name}}
                                </td>
                                <td>{{item.surname}}</td>
                                <td>{{item.mobile}}</td>
                                <!-- <td>{{item.last_login}}</td> -->
                                <td><i class="fa fa-circle {{item.status?'font-success':'font-warning'}} f-12"></i></td>
                                <td>{{item.businessUser?'Business User':'User'}}</td>
                            </tr>
                        </tbody>
                    </table> 
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination
                          [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->